import Link from 'next/link'

import useFormatMessage from '@utils/useFormatMessage'

import Header from '@components/layouts/header/header'

import styles from '../styles/404.module.scss'

export default function FourOhFour() {
  const t = useFormatMessage()

  return (
    <div className={styles.container}>
      <Header />
      <img className={styles.image} src="/images/404.png" alt="404" />
      <h2 className={styles.title}>{t('pageNotFound.title')}</h2>
      <div className={styles.description}>{t('pageNotFound.description')}</div>
      <div className={styles.button}>
        <Link href="/">
          <p className={styles.link}>{t('pageNotFound.link')}</p>
        </Link>
      </div>
    </div>
  )
}
